<template>
  <v-container fluid class="content-wrapper" style="overflow: hidden">
    <div class="content-wrapper">
      <div class="content-body ml-1">
        <!-- Products sell and New Orders -->
        <div class="row match-height">
          <div class="col-12">
            <div class="d-flex flex-row justify-start align-center">
              <h4 class="fw-600 mb-0">
                {{ $t("SigninPromotion.create_free_deal") }}
              </h4>
              <v-btn
                icon
                class="ml-3"
                @click="initAll()"
                :class="loadingData && 'animate__spining'"
                :disabled="loadingData"
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </div>
            <v-skeleton-loader v-if="loadingData" type="heading"></v-skeleton-loader>
            <h6 v-else>{{ $t("SigninPromotion.create_free_deal_label") }}</h6>
          </div>

          <div v-if="loadingData" class="d-flex flex-column" style="width: 100%">
            <v-divider />
            <v-skeleton-loader
              type="list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line"
            ></v-skeleton-loader>
          </div>

          <div v-if="!loadingData" class="col-12 mt-2">
            <span class="border-bottom"></span>
          </div>

          <div v-if="!loadingData" class="col-12 mt-2">
            <h5 class="fw-600">{{ $t("SigninPromotion.create_country_detail") }}</h5>
          </div>

          <div v-if="!loadingData" class="col-md-12 p-md-3">
            <div class="content-body">
              <section id="timeline" class="timeline-left timeline-wrapper">
                <ul class="timeline">
                  <li class="timeline-line"></li>
                  <!-- 1 -->
                  <li class="timeline-item">
                    <div class="timeline-badge">
                      <span
                        class="bg-red bg-lighten-1 d-flex align-center justify-center"
                        data-toggle="tooltip"
                        data-placement="right"
                        title
                        data-original-title
                      >
                        <b class="text-white">1</b>
                      </span>
                    </div>
                    <div class="timeline-card card border-grey border-lighten-2" style>
                      <div class="card-header">
                        <h4 class="card-title">
                          {{ $t("SigninPromotion.nameofpromotion") }}
                        </h4>
                      </div>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="form-group validate">
                            <div class="controls">
                              <input
                                type="text"
                                id="promotionName"
                                class="form-control"
                                style="min-height: 3rem"
                                :placeholder="`${$t('SigninPromotion.nameofpromotion')}`"
                                name
                                v-model="promotionName"
                                @focus="getfocus('promotionName')"
                                @change="getfocus('promotionName')"
                                @mouseleave="mouseOver('promotionName')"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <!-- 2 -->
                  <li class="timeline-item">
                    <div class="timeline-badge">
                      <span
                        class="bg-red bg-lighten-1 d-flex align-center justify-center"
                        data-toggle="tooltip"
                        data-placement="right"
                        title
                        data-original-title
                      >
                        <b class="text-white">2</b>
                      </span>
                    </div>
                    <div class="timeline-card card border-grey border-lighten-2" style>
                      <div class="card-header">
                        <h4 class="card-title">
                          {{ $t("SigninPromotion.create_free_stay") }}
                        </h4>
                        <h6>{{ $t("SigninPromotion.create_free_stay_label") }}</h6>
                      </div>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="d-flex w-100">
                            <div class="mr-3 align-self-center">
                              {{ $t("SigninPromotion.create_free_stay") }}
                            </div>
                            <div
                              class="dropdown select"
                              @click="getfocus('minimum_stay')"
                              @mouseleave="mouseOver('minimum_stay')"
                            >
                              <a id="minimum_stay">
                                <v-select
                                  v-model="minimum_stay"
                                  :items="itemsMinimumStay"
                                  label
                                  :placeholder="$t('SignupStep.select_one')"
                                  :no-data-text="$t('SignupStep.nodata')"
                                  color="#1e9ff2"
                                  solo
                                  flat
                                  dense
                                  single-line
                                  hide-details
                                >
                                  <template v-slot:item="data">
                                    <v-list-item-content
                                      @mouseenter="getfocus('minimum_stay')"
                                      @mouseleave="mouseOver('minimum_stay')"
                                    >
                                      <v-list-item-title
                                        v-html="data.item"
                                        class="title-signup-sj"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-select>
                              </a>
                            </div>
                            <div class="mx-3 d-flex flex-row align-self-center">
                              {{ $t("SigninPromotion.create_free_stay_unit") }}
                              <span class="ml-1 mb-0">
                                {{ $t("SigninPromotion.create_free_stay_example") }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <!-- 3 -->
                  <li class="timeline-item">
                    <div class="timeline-badge">
                      <span
                        class="bg-red bg-lighten-1 d-flex align-center justify-center"
                        data-toggle="tooltip"
                        data-placement="right"
                        title
                        data-original-title
                      >
                        <b class="text-white">3</b>
                      </span>
                    </div>
                    <div class="timeline-card card border-grey border-lighten-2" style>
                      <div class="card-header">
                        <h4 class="card-title">
                          {{ $t("SigninPromotion.create_country_detail") }}
                        </h4>
                        <h6>{{ $t("SigninPromotion.create_free_rateplan_label") }}</h6>
                      </div>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="form-group scope-open">
                            <div class="controls">
                              <div
                                class="dropdown select"
                                @click="getfocus('rateplan')"
                                @mouseleave="mouseOver('rateplan')"
                              >
                                <a id="rateplan">
                                  <v-select
                                    v-model="rate_plan"
                                    :items="itemsAllRatePlanPrice"
                                    item-text="rateplan_name"
                                    item-value="_id"
                                    label
                                    :placeholder="$t('SignupStep.select_one')"
                                    :no-data-text="$t('SignupStep.nodata')"
                                    color="#1e9ff2"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                    @change="selectRatePlanFunc(rate_plan)"
                                  >
                                    <template v-slot:item="data">
                                      <v-list-item-content
                                        @mouseenter="getfocus('rateplan')"
                                        @mouseleave="mouseOver('rateplan')"
                                      >
                                        <v-list-item-title
                                          v-html="data.item.rateplan_name"
                                          class="title-signup-sj"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="rate_plan" class="card-header">
                        <h4 class="card-title">
                          {{ $t("SigninPromotion.create_basic_room") }}
                        </h4>
                      </div>
                      <div v-if="rate_plan" class="card-content">
                        <div class="card-body">
                          <div class="form-group scope-open">
                            <div class="controls">
                              <v-radio-group v-model="all_rooms" :disabled="!rate_plan">
                                <div class="d-flex flex-row align-center justify-start">
                                  <v-radio
                                    :label="$t('SigninPromotion.create_basic_room_all')"
                                    :value="true"
                                    color="#1e9ff2"
                                    @change="checkAllRooms()"
                                  ></v-radio>
                                </div>
                                <div
                                  class="d-flex flex-row align-center justify-start mt-3"
                                >
                                  <v-radio
                                    :label="$t('SigninPromotion.create_basic_room_some')"
                                    :value="false"
                                    color="#1e9ff2"
                                    @change="checkNotAllRooms()"
                                  ></v-radio>
                                </div>
                              </v-radio-group>
                            </div>
                            <div
                              v-if="itemsAllRooms.length && !all_rooms"
                              class="controls pl-2 scope-more"
                            >
                              <div v-for="item in itemsAllRooms" :key="item._id">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    :id="`${item._id}`"
                                    name="rooms"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :value="`${item._id}`"
                                    :checked="item.check"
                                    @change="checkSomeRooms(item)"
                                    :disabled="all_rooms !== false"
                                  />
                                  <label
                                    class="custom-control-label"
                                    :for="`${item._id}`"
                                    >{{ item.value }}</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <!-- 4 -->
                  <li class="timeline-item">
                    <div class="timeline-badge">
                      <span
                        class="bg-red bg-lighten-1 d-flex align-center justify-center"
                        data-toggle="tooltip"
                        data-placement="right"
                        title
                        data-original-title
                      >
                        <b class="text-white">4</b>
                      </span>
                    </div>
                    <div class="timeline-card card border-grey border-lighten-2" style>
                      <div class="card-header">
                        <h4 class="card-title">
                          {{ $t("SigninPromotion.create_free_canreserveornot") }}
                        </h4>
                        <h6>
                          {{ $t("SigninPromotion.create_free_canreserveornot_label") }}
                        </h6>
                      </div>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="form-group scope-open">
                            <div class="controls">
                              <v-radio-group v-model="booking_value">
                                <div class="d-flex flex-row align-center justify-start">
                                  <v-radio
                                    :label="$t('SigninWeekend.dontwant')"
                                    :value="false"
                                    color="#1e9ff2"
                                  ></v-radio>
                                </div>
                                <div
                                  class="d-flex flex-row align-center justify-start mt-3"
                                >
                                  <v-radio
                                    :label="$t('SigninWeekend.want')"
                                    :value="true"
                                    color="#1e9ff2"
                                  ></v-radio>
                                </div>
                              </v-radio-group>
                            </div>
                            <div v-if="booking_value" class="controls pl-2 scope-more">
                              <label>
                                {{
                                  $t("SigninPromotion.create_free_canreserveornot_range")
                                }}
                                :
                              </label>
                              <div class="d-flex pt-1">
                                <!-- <div
                                  class="dropdown select"
                                  style="width: 13rem"
                                  @click="getfocus('starttime')"
                                  @mouseleave="mouseOver('starttime')"
                                >
                                  <a id="starttime">
                                    <v-select
                                      v-model="booking.start_time"
                                      :items="itemsReserveStartTime"
                                      label=""
                                      :placeholder="$t('SignupStep.select_one')"
                                      :no-data-text="$t('SignupStep.nodata')"
                                      color="#1e9ff2"
                                      solo
                                      flat
                                      dense
                                      single-line
                                      hide-details
                                      :disabled="!booking_value"
                                    >
                                      <template v-slot:item="data">
                                        <v-list-item-content
                                          @mouseenter="getfocus('starttime')"
                                          @mouseleave="mouseOver('starttime')"
                                        >
                                          <v-list-item-title
                                            v-html="data.item"
                                            class="title-signup-sj"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </a>
                                </div>-->
                                <input
                                  type="date"
                                  id="choice_startdate"
                                  class="form-control"
                                  name="choice_startdate"
                                  data-placement="top"
                                  data-title="Date"
                                  data-original-title
                                  title
                                  style="min-height: 3rem; width: 15rem"
                                  :disabled="!booking_value"
                                  v-model="booking.start_date"
                                  :max="booking.end_date"
                                />
                                <div class="mx-3 align-self-center">
                                  {{ $t("SigninPromotion.to") }}
                                </div>
                                <input
                                  type="date"
                                  id="choice_enddate"
                                  class="form-control"
                                  name="choice_enddate"
                                  data-placement="top"
                                  data-title="Date"
                                  data-original-title
                                  title
                                  style="min-height: 3rem; width: 15rem"
                                  :disabled="!booking_value"
                                  v-model="booking.end_date"
                                  :min="minRangeBookingFunc()"
                                />
                                <!-- <div
                                  class="dropdown select"
                                  style="width: 13rem"
                                  @click="getfocus('endtime')"
                                  @mouseleave="mouseOver('endtime')"
                                >
                                  <a id="endtime">
                                    <v-select
                                      v-model="booking.end_time"
                                      :items="itemsReserveEndTime"
                                      label=""
                                      :placeholder="$t('SignupStep.select_one')"
                                      :no-data-text="$t('SignupStep.nodata')"
                                      color="#1e9ff2"
                                      solo
                                      flat
                                      dense
                                      single-line
                                      hide-details
                                      :disabled="!booking_value"
                                    >
                                      <template v-slot:item="data">
                                        <v-list-item-content
                                          @mouseenter="getfocus('endtime')"
                                          @mouseleave="mouseOver('endtime')"
                                        >
                                          <v-list-item-title
                                            v-html="data.item"
                                            class="title-signup-sj"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </a>
                                </div>-->
                              </div>
                              <!-- <span class="error-text-input" v-if="warnbookingmonths">
                                *{{$t("RateAndInventory.rest_less")}} 3 {{$t(
                                "SigninPromotion.months"
                                ) }}
                              </span>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <!-- 5 -->
                  <li class="timeline-item">
                    <div class="timeline-badge">
                      <span
                        class="bg-red bg-lighten-1 d-flex align-center justify-center"
                        data-toggle="tooltip"
                        data-placement="right"
                        title
                        data-original-title
                      >
                        <b class="text-white">5</b>
                      </span>
                    </div>
                    <div class="timeline-card card border-grey border-lighten-2" style>
                      <div class="card-header">
                        <h4 class="card-title">{{ $t("SigninPromotion.title_stay") }}</h4>
                        <h6>
                          {{ $t("SigninPromotion.create_basic_reserve_daterange") }}
                        </h6>
                      </div>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="form-group">
                            <div class="controls">
                              <div class="row">
                                <div class="col-xl-4 col-md-6 col-12">
                                  <v-date-picker
                                    v-model="stayRange"
                                    color="#1e9ff2"
                                    range
                                    :min="booking.start_date"
                                    @change="changeStayRangeFunc()"
                                  ></v-date-picker>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <h5>
                                {{ $t("SigninPromotion.create_free_offer_promotion") }}
                              </h5>
                              <h6>
                                {{
                                  $t("SigninPromotion.create_free_offer_promotion_label")
                                }}
                              </h6>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="controls">
                              <div class="ml-2 mt-1 d-flex flex-wrap">
                                <div
                                  class="custom-control custom-checkbox mr-3"
                                  v-for="item in itemsDays"
                                  :key="item.value"
                                >
                                  <input
                                    :id="item.value"
                                    name="days"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :value="item.value"
                                    :checked="item.check"
                                    @change="checkListDays(item)"
                                  />
                                  <label class="custom-control-label" :for="item.value">{{
                                    item.name
                                  }}</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="stayRange.length > 1" class="row">
                            <div class="col-md-12">
                              <div
                                class="alert mb-2 w-75"
                                :class="
                                  warnstaymonths
                                    ? 'alert-warning text-bold'
                                    : 'alert-info'
                                "
                                role="alert"
                              >
                                {{ $t("SigninPromotion.create_basic_discount_range") }}
                                <br />
                                {{ stayRange[0] }}
                                {{ $t("SigninPromotion.to") }}
                                {{ stayRange[1] }}
                                <br />
                              </div>

                              <div class="d-flex flex-column">
                                <span class="error-text-input" v-if="warnstaymonths">
                                  *{{ $t("RateAndInventory.rest_less") }} 3
                                  {{ $t("SigninPromotion.months") }}
                                </span>
                                <span class="error-text-input" v-if="warnearlystaymonths">
                                  *{{ $t("SigninPromotion.invalidstay") }} ({{
                                    $t("SigninPromotion.earlystaybeforebooking")
                                  }})
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <button
                                class="btn btn-warning btn-min-width"
                                @click="resetStayRange()"
                              >
                                {{ $t("SigninPromotion.create_free_reset_dates") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <!-- 6 -->
                  <li class="timeline-item">
                    <div class="timeline-badge">
                      <span
                        class="bg-red bg-lighten-1 d-flex align-center justify-center"
                        data-toggle="tooltip"
                        data-placement="right"
                        title
                        data-original-title
                      >
                        <b class="text-white">6</b>
                      </span>
                    </div>
                    <div class="timeline-card card border-grey border-lighten-2" style>
                      <div class="card-header">
                        <h4 class="card-title">
                          {{ $t("SigninPromotion.create_free_limit") }}
                        </h4>
                      </div>
                      <div class="card-content">
                        <div class="card-body">
                          <div class="form-group scope-open">
                            <div class="controls">
                              <v-radio-group v-model="limit_value">
                                <div class="d-flex flex-row align-center justify-start">
                                  <v-radio
                                    :label="$t('SigninWeekend.dontwant')"
                                    :value="false"
                                    color="#1e9ff2"
                                  ></v-radio>
                                </div>
                                <div
                                  class="d-flex flex-row align-center justify-start mt-3"
                                >
                                  <v-radio
                                    :label="$t('SigninWeekend.want')"
                                    :value="true"
                                    color="#1e9ff2"
                                  ></v-radio>
                                </div>
                              </v-radio-group>
                            </div>
                            <div v-if="limit_value" class="controls pl-2 scope-more">
                              <div class="d-flex pt-1">
                                <div class="align-self-center">
                                  {{ $t("SigninPromotion.create_basic_amount") }}
                                </div>
                                <div class="mx-3 align-self-center">
                                  <input
                                    type="number"
                                    min="0"
                                    @keypress="isNumber($event)"
                                    id="limit"
                                    class="form-control"
                                    placeholder="20"
                                    name
                                    v-model="limit_number"
                                    @focus="getfocus('limit')"
                                    @change="getfocus('limit')"
                                    @mouseleave="mouseOver('limit')"
                                  />
                                </div>
                                <div class="align-self-center">
                                  {{ $t("SigninPromotion.create_basic_unit") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </div>
          </div>

          <div v-if="!loadingData" class="col-md-12 py-3 mb-5">
            <div class="row">
              <div class="col-md-12">
                <v-btn
                  outlined
                  class="mr-3 btn-warning"
                  style="min-height: 2.625rem; min-width: 7.5rem"
                  dark
                  @click="gobackFunc()"
                  >{{ $t("SigninRoomDetails.btn_back") }}</v-btn
                >
                <v-btn
                  outlined
                  class="btn-info"
                  style="min-height: 2.625rem; min-width: 7.5rem"
                  dark
                  @click="savePromotionFunc()"
                  >{{ $t("SigninRoomDetails.btn_save") }}</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninPromotionTypeFreeScreen",
  metaInfo: {
    title: "Promotion",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    promotionTypeID: "",
    promotionID: "",
    value_element: "",
    propertyData: null,
    itemsCountry: [],
    showMonthCurrent: "",
    showMonthNext: "",
    promotionName: "",
    rate_plan: "",
    itemsAllRatePlanPrice: [],
    itemsAllRooms: [],
    all_rooms: null,
    select_rooom_value: null,
    select_rooom: "",
    discount_percent: null,
    stay_value: null,
    stay: {
      start_date: null,
      end_date: null,
      limit_day: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
    },
    default_age_of_voucher: {
      expire_date: null,
      length: null,
    },
    stayRange: [],
    booking_value: null,
    itemsReserveStartTime: ["08:00", "09:00", "10:00", "11:00", "12:00", "13:00"],
    booking: {
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
    },
    itemsReserveEndTime: ["23:00", "00:00", "01:00"],
    limit_value: null,
    limit_number: null,
    minimum_stay: null,
    itemsMinimumStay: [2, 3, 4, 5],
    itemsDays: [],
    reserve_value: null,
    oldPromotionData: {},
    oldPromotionUpdate: false,
    warnstaymonths: false,
    warnearlystaymonths: false,
    warnbookingmonths: false,
    loadingData: false,
    promotionDetail: {},
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.promotionTypeID = self.$route.params.promotionTypeId;
    self.promotionID = self.$route.params.promotionId;
    self.initAll();
  },
  watch: {
    all_rooms() {
      const self = this;
      if (self.all_rooms) self.checkAllRooms();
    },
    "stay.start_date"() {
      const self = this;
      self.booking[0] = self.stay.start_date;
    },
    "stay.end_date"() {
      const self = this;
      self.booking[1] = self.stay.end_date;
    },
    booking() {
      const self = this;
      var tempBookings = [];
      if (self?.booking?.length > 1) tempBookings = self.booking;
    },
    booking_value() {
      const self = this;
      if (self.booking_value && !self.booking.start_date && !self.booking.end_date) {
        self.booking.start_date = moment().format("YYYY-MM-01");
        self.booking.end_date = moment().format("YYYY-MM-DD");
      } else if (!self.booking_value) {
        self.booking.start_date = "";
        self.booking.end_date = "";
      }
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    gobackFunc() {
      this.loading = true;
      // if (window.history?.length > 2) {
      //   EventBus.$emit("goBackPage");
      // } else {
      const link = {
        name: "SigninPromotionScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
      // }
    },
    initAll() {
      const self = this;
      self.loadingData = true;
      self.itemsDays = [
        {
          value: "monday",
          name: self.$t("SigninWeekend.monday"),
          check: true,
        },
        {
          value: "tuesday",
          name: self.$t("SigninWeekend.tuesday"),
          check: true,
        },
        {
          value: "wednesday",
          name: self.$t("SigninWeekend.wednesday"),
          check: true,
        },
        {
          value: "thursday",
          name: self.$t("SigninWeekend.thursday"),
          check: true,
        },
        {
          value: "friday",
          name: self.$t("SigninWeekend.friday"),
          check: true,
        },
        {
          value: "saturday",
          name: self.$t("SigninWeekend.saturday"),
          check: true,
        },
        {
          value: "sunday",
          name: self.$t("SigninWeekend.sunday"),
          check: true,
        },
      ];

      self.initPropertyData();
      setTimeout(() => {
        self.loadingData = false;
      }, 1500);
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempRatePlanPrice = [];
      var haveData = false;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        temp?.promotion?.map((el) => {
          if (el._id == self.promotionID) {
            self.oldPromotionData = el;
            self.promotionDetail = el;
            haveData = true;
            self.promotionName = el?.promotion_name || null;
            self.rate_plan = el?.rate_plan || null;
            self.minimum_stay = el?.minimum_stay || null;
            self.stayRange =
              el?.stay?.start_date && el?.stay?.end_date
                ? [
                    moment(el?.stay?.start_date).format("YYYY-MM-DD"),
                    moment(el?.stay?.end_date).format("YYYY-MM-DD"),
                  ]
                : [];
            self.itemsDays?.map((ele) => {
              const sameDays = el?.stay?.limit_day?.find((p) => p == ele.value);
              if (sameDays) ele.check = true;
            });
            var tmpStayStart = el?.booking?.start_date?.split("T") || null;
            var tmpStayEnd = el?.booking?.end_date?.split("T") || null;
            if (el?.booking?.start_date && el?.booking?.end_date)
              self.booking_value = true;
            else self.booking_value = false;
            self.booking.start_date = el?.booking?.start_date
              ? tmpStayStart[0] || moment(el?.booking?.start_date).format("YYYY-MM-DD")
              : null;
            self.booking.end_date = el?.booking?.end_date
              ? tmpStayEnd[0] || moment(el?.booking?.end_date).format("YYYY-MM-DD")
              : null;
            if (el?.limit_number) self.limit_value = true;
            else self.limit_value = false;
            self.limit_number = el?.limit_number || null;
            self.changeStayRangeFunc();
          }
        });
        if (!haveData) {
          self.showMonthCurrent = moment().format("YYYY-MM-DD");
          self.showMonthNext = moment().add(1, "M").format("YYYY-MM-DD");
        }
        self.initRatePlanData();
      }
    },
    async initRatePlanData() {
      const self = this;
      var tempRatePlanPrice = [];
      var tempOldSelect = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?property_id=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          const hasRoomValue = (currentValue) => currentValue?.room?.value;
          res?.data?.result?.map((el) => {
            if (
              (el?.name || el?.rate_plan?.value) &&
              el?.rooms?.length &&
              el?.rooms.every(hasRoomValue)
            ) {
              if (el._id == self.rate_plan) tempOldSelect = el?._id;
              tempRatePlanPrice.push({
                ...el,
                rateplan_id: el?._id,
                rateplan_name: el?.name || el?.rate_plan?.value,
              });
            }
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsAllRatePlanPrice = tempRatePlanPrice;
        if (self.promotionID) {
          if (tempOldSelect) self.selectRatePlanFunc(tempOldSelect);
        } else {
          self.booking_value = false;
          self.limit_value = false;
        }
      }
    },
    selectRatePlanFunc(item) {
      const self = this;
      var tempOldRoom = [];
      var tempRoom = [];
      const temp = self?.itemsAllRatePlanPrice?.find((p) => p._id == item);
      if (self.rate_plan) {
        self.all_rooms = null;
        self.select_rooom_value = null;
        self.select_rooom = "";
        temp?.rooms?.map((el) => {
          tempRoom.push({
            _id: el?.room?._id || el?._id,
            value: el?.room?.value,
            check: false,
          });
        });
      } else {
        self.all_rooms = null;
        self.select_rooom_value = null;
        self.select_rooom = "";
        self.itemsAllRooms = [];
      }
      self.itemsAllRooms = tempRoom;
      if (self.promotionID && item) {
        var tempCheck = [];
        temp?.rooms?.map((el) => {
          const checkRoom = self.oldPromotionData?.rooms?.find(
            (p) => p == el._id || p == el.room._id
          );
          if (checkRoom) {
            tempOldRoom.push({
              _id: el?.room?._id || el?._id,
              value: el?.room?.value,
              check: true,
            });
            tempCheck.push(el);
          } else {
            tempOldRoom.push({
              _id: el?.room?._id || el?._id,
              value: el?.room?.value,
              check: false,
            });
          }
        });
        self.itemsAllRooms = tempOldRoom;
        if (tempCheck?.length == tempOldRoom?.length) self.all_rooms = true;
        else self.all_rooms = false;
      }
    },
    checkAllRooms() {
      const self = this;
      if (self.all_rooms) self.itemsAllRooms.map((el) => (el.check = true));
      else self.itemsAllRooms.map((el) => (el.check = false));
    },
    checkNotAllRooms() {
      const self = this;
      self.itemsAllRooms.map((el) => (el.check = false));
    },
    checkSomeRooms(item) {
      const self = this;
      if (item.check) item.check = !item.check;
      else item.check = true;
    },
    checkListDays(item) {
      const self = this;
      if (item.check) item.check = !item.check;
      else item.check = true;
    },
    changeStayRangeFunc() {
      const self = this;
      self.stayRange.sort();
      var countEarlyMonths =
        moment(self.stayRange[0]).diff(moment(self.booking.start_date), "months", true) ||
        0;
      var countMonths =
        moment(self.stayRange[1]).diff(moment(self.stayRange[0]), "months", true) || 0;
      if (countEarlyMonths < 0) self.warnearlystaymonths = true;
      else self.warnearlystaymonths = false;
      if (Math.round(parseFloat(countMonths)) >= 3) self.warnstaymonths = false;
      else self.warnstaymonths = true;
    },
    resetStayRange() {
      const self = this;
      self.stayRange = [
        moment().format("YYYY-MM-DD"),
        moment().add(3, "month").format("YYYY-MM-DD"),
      ];
      var countMonths =
        moment(self.stayRange[1]).diff(moment(self.stayRange[0]), "months", true) || 0;
      if (Math.round(parseFloat(countMonths)) >= 3) self.warnstaymonths = false;
      else self.warnstaymonths = true;
    },
    minRangeBookingFunc() {
      const self = this;
      var tmpDate = null;
      tmpDate = self.booking.start_date
        ? moment(self.booking.start_date).add(3, "month").format("YYYY-MM-DD")
        : self.bookingRange[0];
      var countMonths =
        moment(self.booking.end_date).diff(
          moment(self.booking.start_date),
          "months",
          true
        ) || 0;
      if (Math.round(parseFloat(countMonths)) >= 3) self.warnbookingmonths = false;
      else self.warnbookingmonths = true;

      return tmpDate;
    },
    async savePromotionFunc() {
      const self = this;
      var tempRooms = [];
      self?.itemsAllRooms?.map((el) => {
        if (el.check) tempRooms.push(el._id);
      });

      var tempDays = [];
      self?.itemsDays?.map((el) => {
        if (el.check) tempDays.push(el.value);
      });

      var countEarlyMonths =
        moment(self.stayRange[0]).diff(moment(self.booking.start_date), "months", true) ||
        0;

      var countMonths =
        moment(self.stayRange[1]).diff(moment(self.stayRange[0]), "months", true) || 0;

      const obj = {
        promotion_name: self.promotionName,
        rate_plan: self.rate_plan,
        rooms: tempRooms,
        discount_percent: null,
        stay: {
          start_date: self.stayRange[0] || null,
          end_date: self.stayRange[1] || null,
          limit_day: tempDays,
        },
        booking: {
          start_date: self.booking.start_date || null,
          end_date: self.booking.end_date || null,
          start_time: null,
          end_time: null,
        },
        limit_number: self.limit_value ? (self.limit_number || null) : null,
        limit_country: null,
        inactive_dates: null,
        minimum_stay: self.minimum_stay,
        promotion: self.promotionTypeID,
        status:
          self.promotionDetail.status == "expired"
            ? "active"
            : self.promotionDetail.status,
      };

      // console.log("obj: ", obj);

      if (!obj?.promotion_name) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")} ${self
            .$t("SigninPromotion.nameofpromotion")
            .toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!obj?.minimum_stay) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self
            .$t("SigninPromotion.create_free_stay")
            .toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!obj?.rooms?.length) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self.$t("SignupStep.step2").toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!self?.stayRange?.length) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self
            .$t("SigninPromotion.title_stay")
            .toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!tempDays?.length) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self.$t("SigninPolicy.day").toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!obj?.promotion) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self
            .$t("SigninPromotion.promotions")
            .toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (countEarlyMonths < 0) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("SigninPromotion.invalidstay")} (${self.$t(
            "SigninPromotion.earlystaybeforebooking"
          )})`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (Math.round(parseFloat(countMonths)) < 3) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("SigninPromotion.minbooking")} 3 ${self.$t(
            "SigninPromotion.months"
          )}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (
        obj?.promotion_name &&
        obj?.minimum_stay &&
        obj?.rooms?.length &&
        self?.stayRange?.length &&
        tempDays?.length &&
        obj?.promotion &&
        Math.round(parseFloat(countMonths)) >= 3
      ) {
        self.warnstaymonths = false;
        EventBus.$emit("loadingtillend");
        try {
          var res = null;
          if (self.promotionID) {
            res = await self.axios.put(
              process.env.VUE_APP_API +
                `/property/promotion?promotion_id=${
                  self?.promotionID || self?.$route?.params?.promotionId
                }`,
              obj,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
          } else {
            res = await self.axios.post(
              process.env.VUE_APP_API +
                `/property/promotion?property_id=${
                  self?.propertyID || self?.$route?.params?.propertyId
                }`,
              obj,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
          }
          if (res.status == 200) {
            EventBus.$emit("loadingtillend");
            self.oldPromotionUpdate = true;
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          // setTimeout(() => {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          ).then(() => self.gobackFunc());
          // }, 5000);
        }
      } else if (countMonths < 3) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("RateAndInventory.rest_less")} 3 ${self.$t(
            "SigninPromotion.months"
          )}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
        self.warnstaymonths = true;
      } else {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    checkChangeData() {
      const self = this;
      var cantgonextroute = true;
      var tempOldRooms = [];
      self?.oldPromotionData?.rooms?.map((el) => {
        tempOldRooms.push(el);
      });

      var tempRooms = [];
      self?.itemsAllRooms?.map((el) => {
        if (el.check) tempRooms.push(el._id);
      });
      tempRooms.sort();
      tempOldRooms.sort();

      var isSameRooms =
        tempOldRooms?.length == tempRooms?.length &&
        tempOldRooms?.every(function (element, index) {
          return element === tempRooms[index];
        });

      var tempStayRange = [];
      tempStayRange = self.stayRange;

      var tempOldDays = [];
      self.oldPromotionData?.stay?.limit_day?.map((el) => {
        tempOldDays.push(el);
      });

      var tempDays = [];
      self?.itemsDays?.map((el) => {
        if (el.check) tempDays.push(el.value);
      });
      tempDays.sort();
      tempOldDays.sort();

      var isSameDays =
        tempOldDays?.length == tempDays?.length &&
        tempOldDays?.every(function (element, index) {
          return element === tempDays[index];
        });

      if (self.promotionID) {
        if (self.oldPromotionUpdate) {
          cantgonextroute = false;
        } else if (
          self.promotionName !== self.oldPromotionData?.promotion_name ||
          self.minimum_stay !== self.oldPromotionData?.minimum_stay ||
          !isSameRooms ||
          !isSameDays
        ) {
          cantgonextroute = true;
        } else if (
          self.oldPromotionData?.stay?.start_date &&
          self.oldPromotionData?.stay?.end_date
        ) {
          if (
            self.stayRange[0] !==
              moment(self.oldPromotionData?.stay?.start_date).format("YYYY-MM-DD") ||
            self.stayRange[1] !==
              moment(self.oldPromotionData?.stay?.end_date).format("YYYY-MM-DD")
          )
            cantgonextroute = true;
          else cantgonextroute = false;
        } else cantgonextroute = false;
      } else {
        if (self.oldPromotionUpdate) {
          cantgonextroute = false;
        } else if (
          self?.promotionName ||
          self?.minimum_stay ||
          tempRooms?.length ||
          self?.stayRange?.length ||
          tempDays?.length
        ) {
          cantgonextroute = true;
        } else cantgonextroute = false;
      }
      return cantgonextroute;
    },
  },
};
</script>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/timeline.css";
@import "../../../styles/signin/promotion.css";

.v-expansion-panel-header {
  padding: 0 !important;
}
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
